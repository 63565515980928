<template>
  <default-layout>
    <template #content>
      <h1 class="mt-16 text-2xl text-center uppercase">Password Update</h1>
      <article class="p-5 mx-auto">
        <header class="text-center mt-8">
          <p>
            Your password has been out of date for more than 3 months.
            <br />
            It is mandatory that you update before proceeding.
          </p>
        </header>
        <form @submit.prevent="validateBeforeSubmit" class="mt-12 space-y-8">
          <label class="label hidden">Password</label>
          <form-input
            class="w-full"
            v-model="password"
            name="password"
            id="password"
            type="password"
            placeholder="Password"
            required
          />
          <label class="label hidden">Password Confirmation</label>
          <form-input
            class="w-full"
            v-model="passwordConfirmation"
            name="passwordConfirmation"
            id="passwordConfirmation"
            type="password"
            placeholder="Password Confirmation"
            required
          />
          <div class="form-actions">
            <button class="form-button form-button--full mb-4 uppercase">Proceed</button>
          </div>
        </form>
      </article>
    </template>
  </default-layout>
</template>

<script>
import { FormInput } from "@/components";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import { mapGetters } from "vuex";

export default {
  name: "UpdatePassword",
  components: { DefaultLayout, FormInput },
  data: () => ({
    password: "",
    passwordConfirmation: ""
  }),
  computed: {
    ...mapGetters({ currentUser: "auth/user", error: "auth/error" })
  },
  methods: {
    validateBeforeSubmit() {
      if (this.password !== this.passwordConfirmation) {
        return this.$toast.error("Passwords do not match");
      }
      this.updatePassword();
    },

    updatePassword() {
      this.$store
        .dispatch("auth/updatePassword", this.password)
        .then(() => {
          this.$store.dispatch("audit/addRecord", {
            actionName: "UPDATE_PASSWORD",
            clinicUid: this.currentUser.clinicUid,
            notes: `User ${this.currentUser.fullName} changed password`,
            uid: this.currentUser.uid,
            userEmail: this.currentUser.email,
            userName: this.currentUser.userName
          })
        })
        .then(() => this.$router.push("/"))
        .catch((error) => (this.error = error.message));
    }
  }
};
</script>
