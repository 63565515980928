<template>
  <div>
    <app-layout>
      <template #title>Account</template>
      <template #content>
        <div class="mt-20 mx-6">
          <v-text-field class="centered-input" v-model="email" label="Email" type="email" required />
          <v-checkbox v-model="noPrivateNotifications" label="Do not send results to my private email address." />
          <v-text-field class="centered-input" v-model="userName" label="Username" required />
          <v-text-field class="centered-input" v-model="fullName" label="Full Name" required />
          <action-button class="mb-9" @action-button-click="$refs.changePassword.openModal()" outline>
            Change Password
          </action-button>
          <action-button class="mb-9" @action-button-click="sendPasswordResetEmail()" outline>
            Reset Password
          </action-button>
        </div>
        <div class="mt-10 pb-20 flex justify-center items-center">
          <base-button @click="$refs.confirmPassword.openModal()" ring>Save</base-button>
        </div>
      </template>
    </app-layout>
    <modal ref="changePassword">
      <div class="flex flex-column m-4">
        <p>Type new password:</p>
        <form
          class="flex flex-col justify-center space-y-10 mx-auto mt-8 w-72"
          @submit.prevent="validateBeforeSubmit()"
        >
          <form-input
            class="w-full"
            id="password"
            name="password"
            type="password"
            placeholder="Password"
            v-validate="'required|min:6'"
            v-model="newPassword"
          />
          <span v-show="errors.has('password')" class="text-xs text-red-500">{{ errors.first("password") }}</span>
          <div class="flex flex-row justify-between space-x-4 mt-6">
            <base-button @click="$refs.changePassword.closeModal()" primary>Cancel</base-button>
            <base-button outline>Save</base-button>
          </div>
        </form>
      </div>
    </modal>
    <modal ref="confirmPassword">
      <div class="flex flex-column m-4">
        <p>Confirm your password:</p>
        <form class="flex flex-col justify-center space-y-10 mx-auto mt-8 w-72" @submit.prevent="updateProfile()">
          <form-input class="w-full" type="password" placeholder="Password" v-model="passwordConfirmation" />
          <span v-show="errors.has('password')" class="text-xs text-red-500">{{ errors.first("password") }}</span>
          <div class="flex flex-row justify-center space-x-4 mt-6">
            <base-button @click="$refs.confirmPassword.closeModal()" primary>Cancel</base-button>
            <base-button outline>Confirm</base-button>
          </div>
        </form>
      </div>
    </modal>
  </div>
</template>

<script>
import { ActionButton, BaseButton, FormInput, Modal } from "@/components";
import AppLayout from "@/layouts/AppLayout";
import { mapGetters } from "vuex";

export default {
  name: "Profile",
  components: { AppLayout, ActionButton, BaseButton, FormInput, Modal },
  data() {
    return {
      email: "",
      noPrivateNotifications: false,
      userName: "",
      fullName: "",
      newPassword: "",
      passwordConfirmation: ""
    };
  },
  methods: {
    updateProfile() {
      this.$store
        .dispatch("userData/updateUserAuthEmail", {
          newEmail: this.email,
          oldEmail: this.currentUser.email,
          passwordConfirmation: this.passwordConfirmation
        })
        .then(() => {
          this.$store.dispatch("userData/updateUserData", {
            uid: this.currentUser.uid,
            email: this.email,
            noPrivateNotifications: this.noPrivateNotifications,
            userName: this.userName,
            fullName: this.fullName
          });
        })
        .then(() => {
          this.$store.dispatch("audit/addRecord", {
            actionName: "CHANGE_USER_DATA",
            clinicUid: this.currentUser.clinicUid,
            notes: `User data has been updated: ${JSON.stringify({
              email: this.email,
              noPrivateNotifications: this.noPrivateNotifications,
              userName: this.userName,
              fullName: this.fullName
            })}`,
            uid: this.currentUser.uid,
            userEmail: this.currentUser.email,
            userName: this.currentUser.userName
          });
        })
        .then(() => this.$router.push("/"));
    },
    changePassword() {
      this.$store
        .dispatch("clinic/changeUserPassword", { uid: this.currentUser.uid, newPassword: this.newPassword })
        .then(() => {
          this.$store.dispatch("audit/addRecord", {
            actionName: "UPDATE_PASSWORD",
            clinicUid: this.currentUser.clinicUid,
            notes: `User ${this.currentUser.fullName} changed password`,
            uid: this.currentUser.uid,
            userEmail: this.currentUser.email,
            userName: this.currentUser.userName
          });
        })
        .then(() => {
          this.$toast.success("Password has been changed");
          this.$refs.changePassword.closeModal();
        });
    },
    validateBeforeSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.changePassword();
          return;
        }
        console.log("The form is not submitted. Correct the errors");
      });
    },
    sendPasswordResetEmail() {
      this.$store.dispatch("clinic/sendPasswordResetEmail", this.currentUser.authEmail);
    }
  },
  computed: {
    ...mapGetters({ currentUser: "auth/currentUser" })
  },
  created() {
    this.$store.dispatch("auth/fetchCurrentUserData", this.currentUser.uid).then(() => {
      this.email = this.currentUser.email;
      this.noPrivateNotifications = this.currentUser.noPrivateNotifications;
      this.userName = this.currentUser.userName;
      this.fullName = this.currentUser.fullName;
    });
    this.$store.dispatch("userData/fetchUserData", this.currentUser.uid);
  }
};
</script>
